import { contacts } from '../../static/contacts';
import './callUs.scss';

const CallUs = ({ title }) => (
  <section className="call-us">
    <div className="container">
      <h2 className="section-title">
        {title?.split(' ')[0]} broken?
        <br /> Let us <span>help you!</span>
      </h2>

      <p>
        Don't wait to get your life back in order. A-Tech Appliance Repair will fix your broken{' '}
        {title?.split(' ')[0].toLowerCase()} today with our same day repair service. We've helped thousands of families
        over the past 10+ years and we'll help you too.
      </p>

      <div className="call-us__link">
        <a href={`tel:${contacts.phone}`} className="red-button">
          {contacts.phone}
        </a>

        <span>Call us now!</span>
      </div>

      <div className="call-us__payments">
        <img src="/assets/images/payments/mastercard.svg" alt="mastercard" />
        <img src="/assets/images/payments/visa.svg" alt="visa" />
        <img src="/assets/images/payments/discover.svg" alt="discover" />
        <img src="/assets/images/payments/ae.svg" alt="ae" />
      </div>
    </div>
  </section>
);

export default CallUs;
