import './services.scss';

const services = [
  {
    title: 'Refrigerator Repair',
    icon: '/assets/images/services/refrigerator.svg',
    link: '/refrigerator-repair',
  },
  {
    title: 'Freezer Repair',
    icon: '/assets/images/services/freezer.svg',
    link: '/freezer-repair',
  },
  {
    title: 'Washer Repair',
    icon: '/assets/images/services/washer.svg',
    link: '/washer-repair',
  },
  {
    title: 'Dryer Repair',
    icon: '/assets/images/services/dryer.svg',
    link: '/dryer-repair',
  },
  {
    title: 'Dishwasher Repair',
    icon: '/assets/images/services/dishwasher.svg',
    link: '/dishwasher-repair',
  },
  {
    title: 'Range Repair',
    icon: '/assets/images/services/range.svg',
    link: '/range-repair',
  },
  {
    title: 'Oven Repair',
    icon: '/assets/images/services/oven.svg',
    link: '/oven-repair',
  },
  {
    title: 'Microwave Repair',
    icon: '/assets/images/services/microwave.svg',
    link: '/microwave-repair',
  },
];

const Services = () => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">Appliance Serviced</h2>

      <div className="services__grid">
        {services.map((el, index) => (
          <GridItem key={index} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Services;

const GridItem = ({ data }) => (
  <a href={data.link} className="services__grid--item">
    <div className="services__grid--item__icon">
      <img src={data.icon} alt={data.title} />
    </div>
    <h5>{data.title}</h5>
  </a>
);
