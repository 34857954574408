import { Layout } from '../components';

const ThankYou = () => {
  return (
    <Layout pageId="thank-you-page">
      <section class="thank-you">
        <div class="title">Thank you!</div>
        <div class="subtitle">We’ll respond to your request shortly!</div>
        <p>
          A-Tech Repair has the best customers! We truly appreciate your business, and we’re so grateful for the trust
          you’ve placed in us. We look forward to serving you!
        </p>
      </section>
    </Layout>
  );
};

export default ThankYou;
