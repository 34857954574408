import { contacts } from '../../static/contacts';

import './whyWe.scss';

const grid = [
  {
    title: 'Expert & Friendly Technicians',
    icon: '/assets/images/experts.svg',
    description:
      'Our technicians are equipped with the skills, experience and the latest tools. Appointments with our technicians are flexible to fit your schedule.',
  },
  {
    title: 'Affordable Price',
    icon: '/assets/images/price.svg',
    description:
      'We believe in transparency and honesty in pricing. We never overcharge. We don’t hide anything. Our pricing is straightforward and flexible.',
  },
  {
    title: 'Parts & Labor Warranty',
    icon: '/assets/images/labor.svg',
    description:
      'We have been serving our customers with satisfaction for over 10 years. The trust of our valued customers is our biggest asset. All our services are backed by a 90-days parts and labor warranty.',
  },
  {
    title: 'Same Day Repair Service',
    icon: '/assets/images/repair.svg',
    description:
      'We appreciate your time and the importance of solving the problem in a timely manner. We guarantee and perform same-day repairs.',
  },
];

const WhyWe = ({ title }) => (
  <section className="why-we">
    <div className="container">
      <div className="why-we__content">
        <h2 className="section-title">
          Why <span>A-TECH</span> {title}?
        </h2>

        <div className="why-we__content--grid">
          {grid.map((el, index) => (
            <GridItem key={index} data={el} />
          ))}
        </div>
      </div>

      <div className="why-we__coupon">
        <div className="why-we__coupon--inner">
          <h2>
            <span>FREE</span> Service Call
          </h2>
          <span>With Repair</span>
          <p>
            Always Low Cost &<br />
            High Quality Service
          </p>
          <a href="/book-now" className="red-button">
            Book Now
          </a>
          <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </div>
      </div>
    </div>
  </section>
);

export default WhyWe;

const GridItem = ({ data }) => (
  <div className="why-we__content--grid__item">
    <div className="why-we__content--grid__item--header">
      <div className="why-we__content--grid__item--header__icon">
        <img src={data.icon} alt={data.title} />
      </div>
      <h4>{data.title}</h4>
    </div>
    <p>{data.description}</p>
  </div>
);
