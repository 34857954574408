import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';

import './makesAndModels.scss';

const MakesAndModels = () => {
  const { isLoading, data, error } = useFetch(`https://control.a-techrepair.com/wp-json/wp/v2/brands?slug=all-brands`);

  const [brands, setBrands] = useState();
  const [slide, setSlide] = useState();
  const [buttons, setButtons] = useState({
    prev: false,
    next: true,
  });

  const onNextClick = () => {
    setSlide([...brands.slice(25, brands.length)]);
    setButtons({
      prev: true,
      next: false,
    });
  };

  const onPrevClick = () => {
    setSlide([...brands.slice(0, 25)]);
    setButtons({
      prev: false,
      next: true,
    });
  };

  useEffect(() => {
    if (brands?.length) setSlide([...brands?.slice(0, 25)]);
  }, [brands]);

  useEffect(() => {
    if (data?.length) setBrands(data[0]?.acf.brands);
  }, [data]);

  if (isLoading) return;

  return (
    <section className="makes-and-models">
      <div className="container">
        <h2 className="section-title">
          <span>We Fix All Makes & Models</span>
        </h2>

        <div className="makes-and-models__nav">
          <button onClick={onPrevClick} className="button prev" disabled={!buttons.prev} />

          <button onClick={onNextClick} className="button next" disabled={!buttons.next} />
        </div>

        <ul className="makes-and-models__slider">
          {slide?.map((el) => (
            <li key={el.ID}>
              <img src={el.url} alt={`${el.alt} Appliance Repair`} />
            </li>
          ))}
        </ul>

        <ul className="makes-and-models__list">
          {brands?.map((el) => (
            <li key={el.ID}>{el.alt} Appliance Repair</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default MakesAndModels;
