import { Link, NavLink } from 'react-router-dom';

import { contacts } from '../../static/contacts';
import './footer.scss';

const Footer = () => (
  <footer className="page-footer">
    <div className="container">
      <Link to="/" className="page-footer__logo">
        <img src="/assets/images/logo.svg" alt="footer logo" />
      </Link>

      <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>

      <a href={`mailto:${contacts.email}`}>{contacts.email}</a>

      <span className="page-footer__address">{contacts.address}</span>
    </div>
    <Underline />
  </footer>
);

export default Footer;

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="page-footer__underline">
      <div className="container">
        <div>
          <span>©{currentYear} A-TECH Appliance Repair. All Rights Reserved.</span>

          <div className="page-footer__underline--links">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>

            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>

            <NavLink to="/terms-of-use">Terms of Use</NavLink>
          </div>
        </div>

        <a href="https://industry.toporin.com/appliance-repair" target="_blank" rel="noReferrer">
          Designed by <strong>Toporin Studio®</strong> in California
        </a>
      </div>
    </div>
  );
};
